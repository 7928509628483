<!-- eslint-disable -->
<template>
  <v-app id="home">
    <!-- <home-bar /> -->
    <home-header />
    <div class="main-content">
      <div style="background-color:#1590c6;height:80px"></div>
      <v-row align="center" justify="center" style="background-color:#1590c6">
        <h1 class="" style="color:#fded08">TLM EXPRESS</h1>
      </v-row>
      <v-row align="center" justify="center" style="background-color:#1590c6">
        <h1 class="" style="color:#fded08">QUI SOMME NOUS ?</h1>
      </v-row>
      <v-row style="margin-top:20px">
      <v-col cols="12" md="6">
        <v-row align="center" justify="center">
        <b-card
          title="Etablissement"
          :img-src="require('../../assets/Picture1.jpg')"
          img-alt="Image"
          img-top
          tag="article"
          img-height="250"
          style="width:600px;"
          class="mb-2"
        >
          <b-card-text>
            Acteurs incontournables depuis 2011 basée a annaba, nous intervenons dans les secteurs du transport et de la logistique marchandises. <br>
            Notre mission est d’industrialiser la logistique et de la mettre au service de l’économie. <br>
            Notre entreprise exploite une flotte de plus de 30 camions et 20 fourgons. <br>
            En 2021, nous avons transporté et groupé, sur l’ensemble des 30 Wilayas, plus de 10 mille tonnes de marchandises divers.<br>
          </b-card-text>
        </b-card>
      </v-row>
      <v-row align="center" justify="center">
        <b-card
          title="STOCKAGE & MANUTENTION"
          :img-src="require('../../assets/Picture3.jpg')"
          img-alt="Image"
          img-top
          img-height="250"
          tag="article"
          style="width:600px;"
          class="mb-2"
        >
          <b-card-text>            
            <b>VOUS CHERCHEZ  UN PARTENAIRE FIABLE POUR VOTRE LOGISTIQUE DE STOCKAGE OU UN PRESTATAIRE POUR VOS OPERATIONS DE MANUTENTION?</b><br>
            <strong>TLM</strong>  vous propose une solution complète pour votre logistique de stockage, d’approvisionnement et/ou de distribution. <br>
            Optimisez vous niveaux de services, externalisez les processus logistiques et concentrez vous sur votre cœur de métier. <br>
            Notre expérience et savoir faire nous permettent de vous proposer des solutions de transport et logistiques intégrées en fonction de votre secteur d’activité.<br><br>
            <ul>
              <li>AGRO-ALIMENTAIRE</li>
              <li>INDUSTRIE</li>
              <li>SANTÉ</li>
              <li>AUTOMOBILE</li>
              <li>BEAUTÉ</li>
              <li>HORS-GABARIT</li>
              <li>EVENEMENTIEL</li>
            </ul>
          </b-card-text>
        </b-card>
      </v-row>
      <v-row align="center" justify="center">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10409.291873084401!2d7.7147346187489445!3d36.88705067680953!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12f007f6fabaac23%3A0x393c998508f15fa8!2sTlm%20express!5e0!3m2!1sen!2sdz!4v1640297883500!5m2!1sen!2sdz" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
      </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row align="center" justify="center">
          <b-card
          title="Mission"
          :img-src="require('../../assets/Picture2.jpg')"
          img-alt="Image"
          img-top
          img-height="250"
          tag="article"
          style="width:600px;"
          class="mb-2"
        >
          <b-card-text>
            Nous mettons notre expertise à votre service pour vous accompagner dans la réalisation de vos objectifs et ceux de vos clients. A travers une variété d'offres logistiques et d'intégrations dans vos procès, l'externalisation devient possible et s'impose pour votre développement, l'optimisation de vos coûts, la qualité de service, la maitrise des délais. 
          </b-card-text>
        </b-card>
      </v-row>
      <v-row align="center" justify="center">
        <b-card
          title="Service groupage "
          :img-src="require('../../assets/Picture4.jpg')"
          img-alt="Image"
          img-top
          img-height="250"
          tag="article"
          style="width:600px;"
          class="mb-2"
        >
          <b-card-text>
            A l’instar des autres pays, l’organisation du transport dans notre pays doit s’ouvrir sur le groupage qui est aujourd’hui systématique. TLM logistique travaille depuis des années pour développer ce modèle qui nécessite un investissement colossal, des systèmes d’informations et des moyens en ressources humaines très importants. Sur la base d’un réseau traditionnel et des acteurs artisanaux qui activent depuis des années pour relier l’ensemble des régions du pays en optimisant les navettes et les couts de transport, nous nous attelons pour développer ce marché et tisser une toile de façon pragmatique et professionnelle.
          </b-card-text>
        </b-card>
      </v-row>
      <v-row align="center" justify="center">
        <b-card
          title="TLM PROJET"
          :img-src="require('../../assets/Picture5.jpg')"
          img-alt="Image"
          img-top
          img-height="250"
          tag="article"
          style="width:600px;"
          class="mb-2"
        >
          <b-card-text>
            <ul>
              <li>Transport marchandise (classique)</li>
              <li>Service groupage</li>
              <li>TLM déménagement</li>
              <li>TLM express (courrier express)</li>
              <li>E-commerce</li>
              <li>STOCKAGE et distribution</li>
            </ul>
          </b-card-text>
        </b-card>
      </v-row>
      </v-col>
      </v-row>
    </div>
      <div style="height:150px"></div>
      <home-footer/>
  </v-app>
</template>

<script>
/* eslint-disable */
  import HomeBar from './components/core/HomeBar.vue'
  import HomeHeader from './HomeHeader.vue';
  import axios from 'axios'
  export default {
    name: 'AboutUs',
    components: {
      HomeHeader,
      HomeBar,
      HomeFooter: () => import('./components/core/HomeFooter'),
    },
    data() {
      return {
        selectedWilaya: {
          name:null,
          communues: []
        },
        selectedCommunue: null,
        trackingMessage: {
          show: false,
          status: null,
          message: null
        },
        loginMessage: {
          show: false,
          status: null,
          message: null
        },
        tracking_id: null,
        showPackageModal: false,
        messageWhenNoItems: 'There are not items',
        timelineItems: [
          // {
          //   from: new Date(2018, 7,1),
          //   title: 'Name',
          //   description:
          //     'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius earum architecto dolor, vitae magnam voluptate accusantium assumenda numquam error mollitia, officia facere consequuntur reprehenderit cum voluptates, ea tempore beatae unde.'
          // },
        ],
        messagereseved: null,
        cemail: null,
        cname: null,
        telephone: null,
        text: null,
        emailexist: null,
        semail: null,
        spassword: null,
        name: null,
        address: null,
        phone: null,
        wilaya: null,
        communue: null,
        email: null,
        auth_token: null,
        password: null,
        hover1: false,
        hover2: false,
        hover3: false,
        stand1: false,
        stand2: false,
        stand3: false,
        colClass: '',
        colClass2: '',
        postCenters: null,
        selectedPost: 'ANB'
      }
    },
  }
</script>
<style>
.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .5;
    position: absolute;
    width: 100%;
}
.v-application--wrap {
    min-height: 100vh;
    justify-content: center;
}
.container {
  text-align: center;
}
.main-content {
}
#home {
  background-color: white;
}
input {
    border: 2px;
    border-color: black;
    background: white;
}

</style>